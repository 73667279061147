<template>
  <header class="headerVisor">
    <div class="contenedor-logo-visor" >
      <div class="logo-img-visor-ct" >
        <img style="height: 2.2em" @click="home" src="https://app.controlt.com.co/APP/Content/main/res/ControlTLogo.svg"/>
      </div> <!-- <img @click="home" src="https://app.controlt.com.co/APP/Content/main/res/LogoSidebar.png" /> -->
      <div class="line"> |</div>
      <div class="logo-img-visor">
        <img style="height: 1.6em" @click="home" src="@/assets/images/buscar_vehiculo.svg"/>
      </div>
      <div class="container-space"></div>
      <div class="container-title-visor"><p class="title-visor">VISOR</p></div>
      <div class="container-btn-menu-search-drop" id="container-btn-menu-search-drop" >
        <button @click="popupOpen = !popupOpen" type="button" class="btn btn-light btn-search-input-drop" ><i class="icon-search fa fa-search"></i></button>
      </div>
      <div class="container-search-licenseplates" v-show="!popupOpen" >
        <ApiWebVisor />
      </div>

    </div>
    <!-- Menu de opciones para vista tipo responsive -->
    <div class="nav-header-logged-visor" >
      <div class="nav-options-responsive" >
        <div class="nav__option__item-visor" >
          <b-dropdown right class="notifications_drop" size="lg">
            <Notification />
          </b-dropdown>
        </div>
        <b-dropdown right id="dropdown-bar" class="m-md-2 ">
          <template #button-content>
            <i class="fas fa-bars" ></i>
          </template>
          <b-dropdown-item href="#">
            <div style="display: flex; align-items: center; justify-content: flex-start; ">
                  <div class="nav__option__item-visor" >
                    <img @click="home" src="@/assets/images/visorSuit.svg" />
                  </div>
                  <p class="text-menu-drop">Ver todas las apps</p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <div style="display: flex; align-items: center; justify-content: flex-start;">
                  <div class="nav__option__item-visor">
                    <img @click="infoProfile" src="@/assets/images/visorUser.svg" />
                  </div> 
                  <p class="text-menu-drop">Perfil</p>
                </div>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <div style="display: flex; align-items: center; justify-content: flex-start;">
                  <div class="nav__option__item-visor">
                    <img @click="logout" src="@/assets/images/visorExit.svg" />
                  </div>
                  <p class="text-menu-drop">Cerrar sesión</p>
                </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- Menu de opciones para vista no responsive -->
      <div class="nav-options-visor"  >
        <div class="nav__option__item-visor">
          <b-dropdown right class="notifications_drop icon-bell" size="lg">
            <Notification />
          </b-dropdown>
        </div>
        <div class="nav__option__item-visor">
          <img @click="home" src="@/assets/images/visorSuit.svg" />
        </div>
        <div class="nav__option__item-visor">
          <img @click="infoProfile" src="@/assets/images/visorUser.svg" />
        </div>
        <div class="nav__option__item-visor">
          <img @click="logout" src="@/assets/images/visorExit.svg" />
        </div>
      </div>

    </div>
  </header>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";
import Notification from "@/components/Notification.vue";
import apiCall from "../utils/api";
import ApiWebVisor from "./ApiWebVisor.vue";


export default {
  components: {
    Notification,
    ApiWebVisor,

},
  data: function() {
    return {
      name_company: "",
      name_user: "",
      popupOpen: false
    };
  },
  created() {
    var token = localStorage.getItem("user-token");

    // apiCall({url:"notification/getnotificationsbyuser", method:"GET"}).then(res => {
    //     this.name_company = res.data.title;
    //     this.name_user = res.data.id;
    // });
    apiCall({url:"user/info?token="+ token, method:"GET"}).then(res => {
        this.name_company = res.data.nameCompany;
        this.name_user = res.data.name;
      }).catch(() => {
        this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
      });
  },
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    },
    infoProfile: function() {
      this.$router.push("/Profile");
    },
    home: function() {
      this.$router.push("./");
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}
@import "../assets/css/VisorHeader.css";
</style>


