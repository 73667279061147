<template>
  <div class="Profile">
    <HeaderVisor v-if="Header"/>
    <Header />
    <section class="containerPpal">
      <Profile />
    </section>
    <Footer />
  </div>
</template>


<script>
// @ is an alias to /src
import HeaderVisor from "@/components/HeaderVisor.vue";
import Header from "@/components/Header.vue";
import Profile from "@/components/Profile.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "home",
  components: {
    HeaderVisor,
    Header,
    Profile,
    Footer
  }
};
</script>



<style lang="scss">
.labelTitleCard {
  font-weight: bold;
  margin-right: 10px;
}
</style>