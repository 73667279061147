<template>
  <div>
    <div v-show="loading == true">
      <Loading />
    </div>
    <b-card
      title="Datos del usuario"
      img-alt="Card image"
      img-width="200px"
      img-height="200px"
      img-left
      tag="article"
      v-show="loading == false"
    >
      <b-card-text>
        <div>
          <Label class="labelTitleCard">Nombre:</Label>
          <label class="labelTextCard">{{name_user}}</label>

          <Label class="labelTitleCard">Usuario:</Label>
          <label class="labelTextCard">{{username}}</label>

          <Label class="labelTitleCard">Perfil Usuario:</Label>
          <label class="labelTextCard">{{profileName}}</label>

          <Label class="labelTitleCard">Identificación Compañía:</Label>
          <label class="labelTextCard">{{nitCompany}}</label>

          <Label class="labelTitleCard">Nombre Compañía:</Label>
          <label class="labelTextCard">{{nameCompany}}</label>

          <Label class="labelTitleCard">Id Interno Compañía:</Label>
          <label class="labelTextCard">{{idCompany}}</label>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
import apiCall from "../utils/api";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Loading
  },
  data: function() {
    return {
      loading: true,
      name_company: "______________",
      name_user: "______________",
      identificationUser: "______________",
      nameCompany: "______________",
      idCompany: "______________",
      username: "______________",
      nitCompany: "_____________",
      profileName: "____________"
    };
  },
  created() {
    var token = localStorage.getItem("user-token");
    apiCall({ url: "user/info?token=" + token, method: "GET" }).then(res => {
      this.name_company = res.data.nameCompany;
      this.name_user = res.data.name + " " + res.data.lastName;
      this.identificationUser = res.data.identificationUser;
      this.nameCompany = res.data.nameCompany;
      this.idCompany = res.data.idCompany;
      this.username = res.data.username;
      this.loading = false;
      this.nitCompany = res.data.nitCompany;
      this.profileName = res.data.profileName;
    });
  }
};
</script>
<style scoped lang="scss">

.card {
  border: none;
}


.card-body {
  /*border-left: 1px solid silver;*/
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
  border: 3px solid #f6f6f6;
  border-radius: 50px;
  margin-top: 20px;
  min-height: 300px;
  width: 400px;
  padding: 70px 50px;
}

.card-text div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.card-title{
  color: #ff5818ff;
}

@media only screen and (max-width: 600px) {
    .card-body {
        margin-top: 0px;
       border: none;
        padding: 35px 50px;
    }
}

</style>