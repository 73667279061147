<template>
  <div>
    <div class="container-search-licenseplates">
      
      <div class="input-search">
        <i class="icon-search fa fa-search"></i>
        <input type="text" name="licencePlate" id="licencePlate" class="form-control custom-input" placeholder="Ingrese la placa">
      </div>

      <div class="input-group input-date-visor" >
        <input type="text" placeholder="Fecha Inicial" name="date_start" id="date_start" class="form-control" onfocus="(this.type='date')" />
      </div>
    
      <div class="input-group input-date-visor-two" >
        <input type="text" placeholder="Fecha Final" name="date_end" id="date_end" class="form-control" onfocus="(this.type='date')" />
      </div>
      <button @click="send()" class="btn-search-visor" >Buscar</button>
    </div>
  </div>
</template>

<script>
  import LoadScript from 'vue-plugin-load-script';
  import Vue from "vue";
  Vue.use(LoadScript);
  var CTMaps;
  export default {
    created() {
      this.$loadScript("https://app.controlt.com.co/V1/apiweb/maps-2.0.1.js")
        .then(() => {
            setTimeout(function() { 
              CTMaps = this.ct.maps;
              CTMaps.Methods(document.getElementById("map"));
            }, 1000);
        })
    },
    methods: {
      send: function() {
        var token = localStorage.getItem('user-token');
        var licencePlate = document.getElementById("licencePlate").value;
        var date_start = document.getElementById("date_start").value;
        var date_end = document.getElementById("date_end").value;
        CTMaps.CurrentLocationAndRegisters(token, licencePlate, date_start, date_end);
      }
    }
  };
</script>

<style scoped lang="scss">

.input-group-visor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.btn-visor-header {
    width: 100px;
}
.btn{
  color: white;
  background: #1f1853;
  margin-top: 32px;
  width: 45%;
}
.btn:hover{
  background: #4234b2;
}
.date {
  margin-right: 10px;
  width: 38%;
}
.btn-search-visor {
  bottom: 31px; 
  background-color: #1f1853; 
  border-radius: 6px; 
  padding: 3px 10px; 
  margin-left: 10px; 
  color: white;
}

#table {
  overflow: scroll;
}
.input-search {
  position: relative;
  width: 60%; 
  margin-right: 1.5%; 
  display: flex; 
  align-items: center; 
  justify-content: flex-start
}
.icon-search {
  position: absolute;
  left: 12px;
}

input[type="text"]::placeholder {
  text-indent: 5px;
}
.custom-input {
  padding-left: 20%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('../assets/images/visorDate.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
}
.container-search-licenseplates {
  width: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
}
.input-date-visor { 
  width: 50%; 
  margin-right: 1.5%;
}
.input-date-visor-two {
  width: 50%; 
}

@media only screen and (min-width: 360px) and (max-width: 1366px){
 
.container-search-licenseplates {
  margin-top: 10px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  border-radius: 12px;
  background-color: white;
  left: 150px;
  width: 200px;
}
.input-search {
  width: 100%; 
  margin-bottom: 3%;
}

.input-date-visor {
  width: 100%; 
  margin-bottom: 3%;
}
.input-date-visor-two {
  width: 100%; 
  margin-bottom: 3%;
 
}
.btn-search-visor {
  margin: 0;
  width: 100%;
  margin-bottom: 1%;
}
.span-visor {
  width: 27%;
}

}

</style>
